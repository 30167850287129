module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@1_k7z245c452kbitsfla5kktet44/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.14.0_b7dsenh65b5wqzvkfmhpikbugq/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://pesch.flywheelsites.com/graphql","type":{"Comment":{"exclude":true},"UserRole":{"exclude":true},"User":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-anchor-links@1.2.1/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18._r54cfwgrgun6ebeibdlugnyjpi/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#bc8a15","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@1_yvokvufsshs3ww3zqhac4l3elu/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0D20GCFTMV","UA-202804036-1"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@18.3.1__h5ulsqyybp3er75t66jtdqsz6m/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
